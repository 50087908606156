import React, { useState, createContext } from 'react'
import { node } from 'prop-types'

const defaultState = {
  subCategory: null,
  handleSub: () => {}
}

const SubCategoryContext = createContext(defaultState)

const SubCategoryProvider = ({ children }) => {
  const [subCategory, setSub] = useState('')

  const handleSub = newSub => setSub(newSub)

  return (
    <SubCategoryContext.Provider
      value={{
        subCategory,
        handleSub
      }}
    >
      {children}
    </SubCategoryContext.Provider>
  )
}

SubCategoryProvider.propTypes = {
  children: node
}

export default SubCategoryContext
export { SubCategoryProvider }
