/* eslint-disable react/prop-types */
import React from 'react'

import { SubCategoryProvider } from './src/context/SubCategoryContext'
import { ArticleProvider } from './src/context/ArticleContext'

export const wrapRootElement = ({ element }) => (
  <SubCategoryProvider>
    <ArticleProvider>{element}</ArticleProvider>
  </SubCategoryProvider>
)
