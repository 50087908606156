import React, { useState, createContext } from 'react'
import { node } from 'prop-types'

const defaultState = {
  articleId: null,
  handleArticleId: () => {}
}

const ArticleContext = createContext(defaultState)

const ArticleProvider = ({ children }) => {
  const [articleId, setArticleId] = useState('')

  const handleArticleId = newId => setArticleId(newId)

  return (
    <ArticleContext.Provider
      value={{
        articleId,
        handleArticleId
      }}
    >
      {children}
    </ArticleContext.Provider>
  )
}

ArticleProvider.propTypes = {
  children: node
}

export default ArticleContext
export { ArticleProvider }
